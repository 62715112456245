import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Highlight Board`}</h1>
    <p>{`The Highlight Board component is a prominent
way to introduce an experience and present
the user with a clear call to action.`}</p>
    <ComponentPreview componentName="highlight-board--default-story" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Highlight Board Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Highlight Board is typically used at
the top of a page, below the Masthead.
It can also be used as a call to action
on other parts of a page.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Effective use of a Highlight Board involves
a clear, concise headline that grabs attention.`}</li>
      <li parentName="ul">{`The background can either be an image or a solid color.`}</li>
      <li parentName="ul">{`The headline should not exceed 2-3 lines.`}</li>
      <li parentName="ul">{`The background image can either take up the full width
of the site’s main container or extend to the full width
of the viewport.`}</li>
      <li parentName="ul">{`If there is only one call-to-action, it must use the primary button style.`}</li>
      <li parentName="ul">{`A secondary call-to-action must exist with a primary call-to-action.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Default`}</h3>
    <p>{`The Default Highlight Board is used to encourage engagement
with strong imagery or a solid background color.`}</p>
    <ComponentPreview componentName="highlight-board--default-story" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Default Highlight Board Example" mdxType="ComponentPreview" />
    <h3>{`No Image`}</h3>
    <p>{`The No Image Highlight Board omits the background
image and centers the content.`}</p>
    <ComponentPreview componentName="highlight-board--no-image" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="No Image Highlight Board Example" mdxType="ComponentPreview" />
    <h3>{`Stacked`}</h3>
    <p>{`The Stacked Highlight Board places the image,
content, and buttons in a vertical stack.
This places greater emphasis on the image.`}</p>
    <ComponentPreview componentName="highlight-board--stacked" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Stacked Highlight Board Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Highlight Board must contain a headline.`}</li>
      <li parentName="ul">{`Highlight Board should include one or more buttons.`}</li>
      <li parentName="ul">{`Highlight Board may have either a background image,
a solid background color, or no image.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`When using a Highlight Board that includes an image,
be sure to include a descriptive `}<inlineCode parentName="li">{`alt`}</inlineCode>{` attribute.`}</li>
      <li parentName="ul">{`When using a solid background color, the contrast ratio
between the headline and the background must be at least 3:1.`}</li>
      <li parentName="ul">{`To check the contrast ratio of your text on a background image,
check out `}<a parentName="li" {...{
          "href": "https://webaim.org/resources/contrastchecker/"
        }}>{`this resource`}</a>{`.
Use a color picker tool to test the background colors of the image
against the test color.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      